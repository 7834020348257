import * as React from "react";
import { ErrorBoundary } from "@sentry/react";
import { clsx } from "clsx";
import { Fallback } from "components/Fallback";
import { useTranslation } from "react-i18next";
import { Routes, Route, Navigate, NavLink } from "react-router-dom";

import { ErrorBoard } from "./pages/ErrorBoard";
import { RtFail } from "./pages/RtFail/RtFail";
import { routes } from "./routes";

export default function Maintenance() {
  // Preload Translation
  useTranslation("machine", {
    keyPrefix: "machine_detail",
  });

  return (
    <div
      className={
        "-mx-5 -my-5 md:-mx-[35px] basis-0 grow flex flex-col px-4 pt-2 md:px-10 md:pt-4 md:pb-6 box-border overflow-y-scroll"
      }
    >
      <div className="flex items-center justify-between animate-fade-in-move-up">
        <Title />
        <Nav />
      </div>

      <ErrorBoundary fallback={Fallback}>
        <Routes>
          <Route
            path="/error-board/*"
            element={<ErrorBoard className="animate-fade-in-move-up" />}
          />
          <Route
            path="rt-fail"
            element={
              <div className="grow flex flex-col min-h-0">
                <RtFail className="animate-fade-in-move-up" />
              </div>
            }
          />
          <Route
            path="*"
            element={
              <Navigate to={routes.majorTrouble.machineUnsellable} replace />
            }
          />
        </Routes>
      </ErrorBoundary>
    </div>
  );
}

function Title() {
  const { t } = useTranslation("maintenance");

  const h1Ref = React.useRef<HTMLHeadingElement>(null);
  React.useLayoutEffect(() => {
    const animations = h1Ref.current?.getAnimations({
      subtree: true,
    });

    animations?.forEach((animation) => {
      animation.currentTime = 3000;
    });
  }, []);

  return (
    <h1 className="m-0 p-0 text-h6 text-on-surface-high leading-6" ref={h1Ref}>
      <span>{t("maintenance") + " / "}</span>
      <Routes>
        <Route
          path="error-board/*"
          element={
            <span
              className={clsx(
                "text-primary-600 inline-block animate-fade-in-move-up"
              )}
              key="errorBoard"
            >
              {t("errorBoard")}
            </span>
          }
        />

        <Route
          path="rt-fail"
          element={
            <span
              className={clsx(
                "text-primary-600 animate-fade-in-move-up inline-block"
              )}
              key="rtFail"
            >
              {t("rtFail.title")}
            </span>
          }
        />
      </Routes>
    </h1>
  );
}

const ROUTES: Array<{
  to: string;
  SVG: (props: React.SVGProps<SVGPathElement>) => JSX.Element;
}> = [
  {
    to: routes.errorBoard,
    SVG: (props: { className: string }): JSX.Element => {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 17H9V12H7V17ZM15 17H17V7H15V17ZM11 17H13V14H11V17ZM11 12H13V10H11V12ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z"
            className={props.className}
          />
        </svg>
      );
    },
  },
  {
    to: routes.rtFail,
    SVG: (props: { className: string }): JSX.Element => {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 22C11.1167 22 9.81667 21.7375 8.6 21.2125C7.38333 20.6875 6.325 19.975 5.425 19.075C4.525 18.175 3.8125 17.1167 3.2875 15.9C2.7625 14.6833 2.5 13.3833 2.5 12C2.5 10.6167 2.7625 9.31667 3.2875 8.1C3.8125 6.88333 4.525 5.825 5.425 4.925C6.325 4.025 7.38333 3.3125 8.6 2.7875C9.81667 2.2625 11.1167 2 12.5 2C13.8833 2 15.1833 2.2625 16.4 2.7875C17.6167 3.3125 18.675 4.025 19.575 4.925C20.475 5.825 21.1875 6.88333 21.7125 8.1C22.2375 9.31667 22.5 10.6167 22.5 12C22.5 13.3833 22.2375 14.6833 21.7125 15.9C21.1875 17.1167 20.475 18.175 19.575 19.075C18.675 19.975 17.6167 20.6875 16.4 21.2125C15.1833 21.7375 13.8833 22 12.5 22ZM6.825 17.675L12.5 12V4C10.2667 4 8.375 4.775 6.825 6.325C5.275 7.875 4.5 9.76667 4.5 12C4.5 13.0667 4.7 14.0917 5.1 15.075C5.5 16.0583 6.075 16.925 6.825 17.675Z"
            className={props.className}
          />
        </svg>
      );
    },
  },
];

function Nav() {
  return (
    <nav className="inline-flex">
      {ROUTES.map(({ to, SVG }) => (
        <NavLink
          key={to}
          to={to}
          className={({ isActive }) =>
            clsx(
              "p-3 flex items-center justify-center transition-colors border border-outline first:rounded-tl-lg last:rounded-tr-lg first:rounded-bl-lg last:rounded-br-lg group",
              isActive
                ? "bg-navigation-variant border-primary"
                : "hover:bg-primary-50"
            )
          }
        >
          {({ isActive }) => (
            <SVG
              className={clsx(
                isActive
                  ? "fill-on-primary-high"
                  : "fill-primary-500 group-hover:fill-primary-300",
                "transition-colors"
              )}
            />
          )}
        </NavLink>
      ))}
    </nav>
  );
}

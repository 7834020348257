import * as React from "react";
import { ErrorBoundary } from "@sentry/react";
import { Routes, Route, Navigate } from "react-router-dom";

import { CoffeeErrorTable } from "./CoffeeErrorTable";
import { IceErrorTable } from "./IceErrorTable";
import { IceRateLowTable } from "./IceRateLowTable";
import { MachineUnsellableTable } from "./MachineUnsellableTable";
import { OfflineTable } from "./OfflineTable";
import { OutOfServiceTable } from "./OutOfServiceTable";
import { SodaErrorTable } from "./SodaErrorTable";
import { SpiralUnsellableTable } from "./SpiralUnsellableTable";
import { WhipperTable } from "./WhipperTable";
import { routes } from "../../../../routes";
import { Fallback } from "../../components/Fallback";
import { useSelectedZones } from "../../providers/SelectedZoneProvider";

export function MajorTroubleTables() {
  const { serviceZoneIds, refillZoneIds } = useSelectedZones();
  const key = serviceZoneIds?.join("") + refillZoneIds?.join("");

  return (
    <Routes>
      <Route
        path="/machine-unsellable"
        element={
          <ErrorBoundary fallback={Fallback} key={"machine-unsellable" + key}>
            <MachineUnsellableTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/out-of-service"
        element={
          <ErrorBoundary fallback={Fallback} key={"out-of-service" + key}>
            <OutOfServiceTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/offline"
        element={
          <ErrorBoundary fallback={Fallback} key={"offline" + key}>
            <OfflineTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/ice-error"
        element={
          <ErrorBoundary fallback={Fallback} key={"ice-error" + key}>
            <IceErrorTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/coffee-error"
        element={
          <ErrorBoundary fallback={Fallback} key={"coffee-error" + key}>
            <CoffeeErrorTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/ice-rate-low"
        element={
          <ErrorBoundary fallback={Fallback} key={"ice-rate-low" + key}>
            <IceRateLowTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/soda-error"
        element={
          <ErrorBoundary fallback={Fallback} key={"soda-error" + key}>
            <SodaErrorTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/whipper"
        element={
          <ErrorBoundary fallback={Fallback} key={"whipper" + key}>
            <WhipperTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/spiral-unsellable"
        element={
          <ErrorBoundary fallback={Fallback} key={"spiral-unsellable" + key}>
            <SpiralUnsellableTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="*"
        element={
          <Navigate
            to={routes.majorTrouble.machineUnsellable}
            replace
          />
        }
      />
    </Routes>
  );
}

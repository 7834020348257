import { queryClient } from "index";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";
import { userKeys } from "userandauth/hooks/userKeys";
import { IUser } from "userandauth/interfacesUser";
import { useAuthStore } from "userandauth/useAuthStore";

export type Country =
  | "MALAYSIA"
  | "THAILAND"
  | "AUSTRALIA"
  | "SINGAPORE"
  | "UNITED_ARAB_EMIRATES";

export const ORG_IDS = {
  PENANG: "97d3c901-3a87-410c-85f8-6ec06574887c",
  PLUS_MY: "cc24e483-0a68-47e4-881a-ad5109ce4e1f",
  JOHOR_MY: "115d97bb-c4ea-459e-8742-cc297ee6be4d",
  FT_VIC: "5fb6cfd3-0e8f-4b06-81e4-38f8530fe5b0",
  FT_QLD: "38d6c72e-cb17-441c-a626-b53226c5fe1c",
  FT_NSW: "4d751886-2c73-4b3c-a47c-83c28f138887",
  PTN: "7752e8d6-27f1-4503-aa79-7709ee835cd5",
  BRM_BURIRAM: "2e229c8a-7777-47da-b08a-eb5ac82e23e1",
  USA_PEPSI: "33c01c05-1749-45a4-b135-d17b699169a9",

  A_K: "6b76a50c-327a-4a46-9690-ccfe1c20e1da",
  CDRTM: "1a603b06-e0c4-46c4-b2a6-18b23af8d648",
  CHIANGRAI_SATELLITE: "120b13e9-1271-433d-a09e-28279346f38a",
  DCK: "eccd3a4f-e893-4d1b-be54-4db8fec04e53",
  DECH: "b8a07a4b-b290-41be-b21b-0f011aeaaa96",
  DJI: "90fb9644-1081-4f75-b0ee-b1d3e611fbf0",
  EBDC: "b0b771ee-814d-4442-8194-129b257efdb0",
  H_W: "7b0fa347-28e7-499d-b7a7-ee526e7f5577",
  H2O: "743b1328-c8ce-4349-a47c-d5a0b9950368",
  IBL: "2af5168a-2adc-40c1-a47c-54a4cc310b3e",
  LUCKYD: "9aa1cb57-1b11-4977-9f0e-a581cd6c0cec",
  NP_PLUS: "b2686f55-4516-44c6-9197-9a4e966e256d",
  PIJITINTERNET: "ca41d07f-0740-4a59-828d-f578af6e50f1",
  PPPJS: "7dc37e0f-4be4-4cc8-9e10-6da92d7792bf",
  RAYONGSUASAN: "4b47ba16-1c7a-4227-bf62-2d746cb04fd0",
  S_D_VENDING: "35d6e7af-dd09-4c71-a51e-4cf61d29f0bf",
  SERVICEEXPRESS: "86f58c24-b935-450b-82b3-641bfd809b60",
  SGB: "243267d0-2c15-4d47-9ca9-2b0e3a86bd28",
  SIENTAO: "634e9f66-9b9b-43a1-a754-c09c4e99e9a9",
  SINGHAPARAUY: "bc7b444c-5ae5-496e-8540-736825d0dd1d",
  SMILE: "4f570107-bbc2-4163-944a-062c2c96d63e",
  SRIKAEW: "a8885e0c-d978-4c0d-8e77-dfd7bea0115a",
  SRSTC: "a128d9ad-7b5c-49a6-b07b-b38251642619",
  TAONINJA: "400ac56a-c121-4057-916e-d6871f8e9e4b",
  TBIN: "4ac76786-90de-4dcf-a468-3c0c0fc58037",
  TELECOM_CENTER: "50dec44f-301f-4900-859b-0dafa788c322",
  THREEO: "b634bcf3-7ef5-4ecd-87e8-d399a640b09c",
  UTHAI: "e927aadf-3217-44cb-bf7a-b1d797810422",
  FORTH_CK: "ca656402-5cba-458d-a94b-b49e92966da7",
  MALAYSIAN: "8e65d9c3-5823-408c-9223-c39b2b0379b5",
  SINGAPORE: "e9c7d0d3-8e3a-45b9-82cb-6030b21c151b",
  VNG: "7416c993-d99c-4e55-ae33-52ed721f7adc",
  GMP: "bfd943aa-7934-4d48-8859-09a0e60c60d4",
  VERY_GOOD: "9c8bc55b-c229-4add-8a01-a2dae494dad8",
  P_SIAM: "e7b2ab0f-a76e-4bf7-964e-46553ac7fc08",
  ITECHCOM: "dcda58a1-6e4b-4186-b409-c8983a38c06c",

  AVG: "a28d10f3-4883-41f9-968a-06a971bc3100",
  Do_Not_Use: "efb97684-344c-4c72-9e64-ae8a8ac74c70",
  Forth_Test: "24ad6ed9-3d14-4b32-a480-42314b5ec368",
  HD_Sattlelite: "bf4288eb-e1b3-48e9-99df-1c7a0f751bb1",
  INFINITY: "be8fc340-dc24-421a-96e5-5a7a4a14fe42",
  InterTopup: "a7e8b47b-7b44-48af-b6c0-f5d09735723e",
  PRATOOMKAMDEE: "2a8c9b57-bd93-4097-ab0b-a6f07b3cee5b",
  Telethai: "94463c9b-9a06-4fa7-b45f-5feb11030037",
  W_AND_J: "4055ba15-31ae-4b78-a4fd-7bff730c4e59",
  Thaicomoa: "90e5d05f-eb6b-477c-9ed6-fb94c089b7b8",

  DUBAI: "d0ba1000-ea3d-436a-bdfb-6ce07ef540d8",
};

const ORG_ID_BY_COUNTRY: Record<Country, { [orgName: string]: string }> = {
  SINGAPORE: {
    SINGAPORE: ORG_IDS.SINGAPORE,
  },
  MALAYSIA: {
    PENANG: ORG_IDS.PENANG,
    MALAYSIAN: ORG_IDS.MALAYSIAN,
    PLUS_MY: ORG_IDS.PLUS_MY,
    JOHOR_MY: ORG_IDS.JOHOR_MY,
  },
  THAILAND: {
    FORTH_CK: ORG_IDS.FORTH_CK,
    PTN: ORG_IDS.PTN,
    BRM_BURIRAM: ORG_IDS.BRM_BURIRAM,
  },
  AUSTRALIA: {
    FT_VIC: ORG_IDS.FT_VIC,
    FT_QLD: ORG_IDS.FT_QLD,
    FT_NSW: ORG_IDS.FT_NSW,
    USA_PEPSI: ORG_IDS.USA_PEPSI,
  },
  UNITED_ARAB_EMIRATES: {
    DUBAI: ORG_IDS.DUBAI,
  },
} as const;

const COUNTRY_BY_ORG_ID_MAP = Object.entries(ORG_ID_BY_COUNTRY).reduce(
  (acc, [country, orgs]) => {
    Object.values(orgs).forEach((orgId) => {
      acc[orgId] = country as Country;
    });
    return acc;
  },
  {} as Record<string, Country | undefined>
);

/**
 * Gets the country of the org id
 */
export function getCountryByOrgId(orgId: string): Country | undefined {
  return COUNTRY_BY_ORG_ID_MAP[orgId];
}
/**
 * Get's user's country through user's org id
 */
export function getUserCountry(defaultCountry?: Country): Country {
  const { orgId } = getCurrentUserFromCache();
  return getCountryByOrgId(orgId) ?? defaultCountry;
}

export function getCurrentUserFromCache() {
  const userId = useAuthStore.getState().userId;
  const data = queryClient.getQueryData<IUser>(userKeys.user(userId));
  return data;
}

export function useIsMalaysiaUser() {
  const { data: userInfo } = useGetCurrentUser();
  return getCountryByOrgId(userInfo.orgId) === "MALAYSIA";
}

export function useIsSingaporeUser() {
  const { data: userInfo } = useGetCurrentUser();
  return getCountryByOrgId(userInfo.orgId) === "SINGAPORE";
}

export function useIsForthCKUser() {
  const { data: userInfo } = useGetCurrentUser();
  return userInfo.orgId === ORG_IDS.FORTH_CK;
}

export function useIsMalaysianUser() {
  const { data: userInfo } = useGetCurrentUser();
  return userInfo.orgId === ORG_IDS.MALAYSIAN;
}

export function useIsAdmin() {
  const { data: userInfo } = useGetCurrentUser();
  return isAdminRole(userInfo.role);
}

export function useIsAdminContract() {
  const { data: userInfo } = useGetCurrentUser();
  return isAdminContractRole(userInfo.role);
}

export function useIsSingaporeanUser() {
  const { data: userInfo } = useGetCurrentUser();
  return userInfo.orgId === ORG_IDS.SINGAPORE;
}

export function useIsAustralianUser() {
  const { data: userInfo } = useGetCurrentUser();
  return userInfo.orgId === ORG_IDS.FT_VIC;
}

export function useIsUnitedArabEmiratesUser() {
  const { data: userInfo } = useGetCurrentUser();
  return userInfo.orgId === ORG_IDS.DUBAI;
}

export function useIsOperator() {
  const { data: userInfo } = useGetCurrentUser();
  return isOperatorRole(userInfo.role);
}

export function useIsClerk() {
  const { data: userInfo } = useGetCurrentUser();
  return isClerkRole(userInfo.role);
}

export function useIsTechnician() {
  const { data: userInfo } = useGetCurrentUser();
  return isTechnicianRole(userInfo.role);
}

export function useIsRouteman() {
  const { data: userInfo } = useGetCurrentUser();
  return isRoutemanRole(userInfo.role);
}

export function useIsViewer() {
  const { data: userInfo } = useGetCurrentUser();
  return isViewerRole(userInfo.role);
}

export function useIsSuperViewer() {
  const { data: userInfo } = useGetCurrentUser();
  return isSuperViewerRole(userInfo.role);
}

export const ROLE = {
  ADMIN: "admin",
  ADMIN_CONTRACT: "admin-contract",
  VIEWER: "viewer",
  SUPER_VIEWER: "super-viewer",
  CLERK: "clerk",
  ROUTEMAN: "routeman",
  OPERATOR: "operator",
  TECHNICIAN: "technician",
} as const;

type Role = (typeof ROLE)[keyof typeof ROLE];

const ROLE_TRANSLATIONS = {
  [ROLE.ADMIN]: "label_admin",
  [ROLE.ADMIN_CONTRACT]: "label_admin_contract",
  [ROLE.VIEWER]: "label_viewer",
  [ROLE.SUPER_VIEWER]: "label_super_viewer",
  [ROLE.CLERK]: "label_clerk",
  [ROLE.ROUTEMAN]: "label_routeman",
  [ROLE.OPERATOR]: "label_operator",
  [ROLE.TECHNICIAN]: "label_technician",
} as const;

export function isAdminRole(role: Role | (string & {})) {
  return role === ROLE.ADMIN;
}

export function isAdminContractRole(role: Role | string) {
  return role === ROLE.ADMIN_CONTRACT;
}

export function isViewerRole(role: Role | string) {
  return role === ROLE.VIEWER;
}

export function isSuperViewerRole(role: Role | string) {
  return role === ROLE.SUPER_VIEWER;
}

export function isClerkRole(role: Role | string) {
  return role === ROLE.CLERK;
}

export function isRoutemanRole(role: Role | string) {
  return role === ROLE.ROUTEMAN;
}

export function isOperatorRole(role: Role | string) {
  return role === ROLE.OPERATOR;
}

export function isTechnicianRole(role: Role | string) {
  return role === ROLE.TECHNICIAN;
}

export function getRoleTranslation(role: Role | (string & {})) {
  return ROLE_TRANSLATIONS[role];
}

import * as React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ActionDropDown } from "./ActionDropdown";
import { EventStatus } from "./EventStatus";
import { getStatusIndicator } from "./getStatusIndicator";
import { Machine } from "./useRealTimeQuery";

export function MachineCard({ machine }: { machine: Machine }) {
  const { t } = useTranslation("maintenance", {
    keyPrefix: "rtFail",
  });

  return (
    <div className="bg-white p-2 [box-shadow:0px_1px_4px_0px_rgba(0,0,0,0.13)] animate-fade-in [animation-duration:300ms] rounded-[4px] border border-outline relative">
      <div className="absolute top-2 right-2.5 z-10">
        <ActionDropDown machineId={machine.machineId} />
      </div>
      <div className="grid [grid-template-columns:70px_1fr_100px] px-[6px] pb-[6px] border-b border-outline ">
        <div className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
          {t("machineId")}
        </div>
        <div className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
          {t("location")}
        </div>
        <div className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
          {t("grade")}
        </div>
        <Link
          to={`/machines/detail/${machine.machineId}/status`}
          className="text-subtitle2 leading-6 text-primary-500 animate-fade-in"
        >
          {machine.machineId}
        </Link>
        <div className="text-subtitle2 leading-4 text-on-surface-high box-border pt-1 pr-5 animate-fade-in">
          {machine.machine?.location.locationName ?? t("unknownLocation")}
        </div>
        <div className="text-subtitle2 leading-4 text-on-surface-high box-border pt-1 pr-5 animate-fade-in whitespace-nowrap">
          {"gradeWeekday" in machine.machine.location
            ? (machine.machine.location.gradeWeekday ?? "-")
            : "-"}
          {"/"}
          {"gradeWeekend" in machine.machine.location
            ? (machine.machine.location.gradeWeekend ?? "-")
            : "-"}
        </div>
      </div>
      <div className="grid [grid-template-columns:70px_1fr] mt-[6px] px-[6px] gap-y-[4px]">
        <div className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
          {t("time")}
        </div>
        <div className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
          {t("status")}
        </div>

        {machine.events.map((event, index) => (
          <React.Fragment key={index}>
            <div className="flex items-center justify-between self-start py-[2px] pr-2.5">
              <div className="text-body2 leading-5 text-on-surface-medium">
                {moment(event.timestamp).format("HH:mm")}
              </div>
              {getStatusIndicator(event.type)}
            </div>
            <div className="pr-2.5 py-[2px]">
              <EventStatus event={event} />
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

import * as React from "react";
import Typography from "components/Typography";
import { ActionDropDown } from "containers/maintenance/pages/RtFail/ActionDropdown";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Chevron } from "../../components/Chevron";
import { ErrorCodeCell } from "../../components/ErrorCodeCell";
import { MaintenanceTable } from "../../components/MaintenanceTable";
import { StaffAtMachineCell } from "../../components/StaffAtMachineCell";
import { useErrorBoard } from "../../hooks/useErrorBoard";
import { useSortQueryParam } from "../../hooks/useSortQueryParam";

export function S2Table() {
  const { state } = useErrorBoard();
  const { t } = useTranslation("maintenance");
  const [sort, toggleSort] = useSortQueryParam();

  if (state.status === "error") {
    throw state.error;
  }

  return (
      <MaintenanceTable
        isLoading={state.status === "loading" || state.status === "refreshing"}
        data={
          state.data.table?.type === "S2" ? state.data.table.data : undefined
        }
        containerStyles={{
          scrollPaddingLeft: "100px",
        }}
        getKey={(dataPoint) => dataPoint.id}
        isPaginating={state.status === "paginating"}
        columns={[
          {
            id: "MACHINE_ID",
            commonStyle: {
              minWidth: "100px",
              flex: "0 0 100px",
              padding: "2px 8px",
              boxSizing: "border-box",
              lineHeight: "16px",
            },
            headerStyle: {
              position: "sticky",
              top: "0",
              left: "0",
              backgroundColor: "#F5F5F5",
            },
            headerCell: () => (
              <div className="flex gap-2 items-center justify-between">
                <Typography type="caption" color="onSurfaceHigh">
                  {t("machineId")}
                </Typography>
                <Chevron
                  sortDir={
                    sort.sortCol === "machine-id" ? sort.sortDir : undefined
                  }
                  onClick={() => {
                    toggleSort({
                      col: "machine-id",
                    });
                  }}
                />
              </div>
            ),
            rowStyle: {
              display: "flex",
              alignItems: "center",
              position: "sticky",
              left: "0",
              backgroundColor: "white",
            },
            cell: (event) => (
              <Link to={`/machines/detail/${event.machineId}/status`}>
                <Typography type="subtitle-2" color="primary500">
                  {event.machineId}
                </Typography>
              </Link>
            ),
            loadingRowStyle: {
              position: "sticky",
              left: "0",
              backgroundColor: "white",
              zIndex: 1,
            },
          },
          {
            id: "ERROR_CODE",
            commonStyle: {
              minWidth: "96px",
              flex: "0 0 96px",
              padding: "2px 8px",
              boxSizing: "border-box",
              lineHeight: "16px",
            },
            headerCell() {
              return (
                <div className="flex gap-2 items-center justify-between">
                  <Typography type="caption" color="onSurfaceHigh">
                    {t("errorCode")}
                  </Typography>
                  <Chevron
                    sortDir={
                      sort.sortCol === "error-code" ? sort.sortDir : undefined
                    }
                    onClick={() => {
                      toggleSort({
                        col: "error-code",
                      });
                    }}
                  />
                </div>
              );
            },
            rowStyle: {
              display: "flex",
              alignItems: "center",
              scrollSnapAlign: "start",
            },
            cell(event) {
              return <ErrorCodeCell {...event} />;
            },
          },
          {
            id: "GRADE",
            commonStyle: {
              minWidth: "128px",
              flex: "0 0 128px",
              padding: "2px 8px",
              boxSizing: "border-box",
              lineHeight: "16px",
            },
            rowStyle: {
              display: "flex",
              alignItems: "center",
              scrollSnapAlign: "start",
            },
            headerStyle: {
              display: "flex",
              flexDirection: "column",
            },
            cell(event) {
              return (
                <Typography type="body-2" color="onSurfaceHigh">
                  {event.grade.weekday ?? "-"}/{event.grade.weekend ?? "-"}
                </Typography>
              );
            },
            headerCell() {
              return (
                <>
                  <div className="flex gap-2 items-center justify-between">
                    <Typography type="caption" color="onSurfaceHigh">
                      {t("grade")}
                    </Typography>
                    <Chevron
                      sortDir={
                        sort.sortCol === "grade" ? sort.sortDir : undefined
                      }
                      onClick={() => {
                        toggleSort({
                          col: "grade",
                          firstDirection: "desc",
                        });
                      }}
                    />
                  </div>
                  <Typography type="caption" color="onSurfaceMedium">
                    {t("weekday")}/{t("weekend")}
                  </Typography>
                </>
              );
            },
          },
          {
            id: "LOCATION",
            rowStyle: {
              display: "flex",
              alignItems: "center",
              scrollSnapAlign: "start",
            },
            cell(event) {
              return (
                <Typography type="body-2" color="onSurfaceHigh">
                  {event.location}
                </Typography>
              );
            },
            commonStyle: {
              flex: "1 0 200px",
              minWidth: "200px",
              padding: "2px 8px",
              boxSizing: "border-box",
              lineHeight: "16px",
            },
            headerCell() {
              return (
                <Typography type="caption" color="onSurfaceHigh">
                  {t("locationName")}
                </Typography>
              );
            },
          },
          {
            id: "SERVICE_ZONE",
            rowStyle: {
              display: "flex",
              alignItems: "center",
              scrollSnapAlign: "start",
            },
            cell(event) {
              return (
                <Typography type="body-2" color="onSurfaceHigh">
                  {event.serviceZone}
                </Typography>
              );
            },
            commonStyle: {
              minWidth: "110px",
              flex: "0 0 110px",
              padding: "2px 8px",
              boxSizing: "border-box",
              lineHeight: "16px",
            },
            headerCell() {
              return (
                <Typography type="caption" color="onSurfaceHigh">
                  {t("serviceZone")}
                </Typography>
              );
            },
          },
          {
            id: "REFILL_ZONE",
            commonStyle: {
              minWidth: "110px",
              flex: "0 0 110px",
              padding: "2px 8px",
              boxSizing: "border-box",
              lineHeight: "16px",
            },
            rowStyle: {
              display: "flex",
              alignItems: "center",
              scrollSnapAlign: "start",
            },
            cell(event) {
              return (
                <Typography type="body-2" color="onSurfaceHigh">
                  {event.refillZone}
                </Typography>
              );
            },
            headerCell() {
              return (
                <Typography type="caption" color="onSurfaceHigh">
                  {t("refillZone")}
                </Typography>
              );
            },
          },
          {
            id: "DATE_TIME",
            rowStyle: {
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              scrollSnapAlign: "start",
            },
            commonStyle: {
              minWidth: "104px",
              flex: "0 0 104px",
              padding: "2px 8px",
              boxSizing: "border-box",
              lineHeight: "16px",
            },
            cell(event) {
              return (
                <>
                  <Typography type="body-2" color="onSurfaceHigh">
                    {moment(event.date).format("HH:mm")}
                  </Typography>
                  <Typography type="caption" color="onSurfaceDisabled">
                    {moment(event.date).format("MMM DD, YYYY")}
                  </Typography>
                </>
              );
            },
            headerCell() {
              return (
                <div className="flex gap-2 items-center justify-between">
                  <Typography type="caption" color="onSurfaceHigh">
                    {t("dateTime")}
                  </Typography>
                  <Chevron
                    sortDir={
                      sort.sortCol === "timestamp" ? sort.sortDir : undefined
                    }
                    onClick={() => {
                      toggleSort({
                        col: "timestamp",
                        firstDirection: "desc",
                      });
                    }}
                  />
                </div>
              );
            },
          },
          {
            id: "STAFF_AT_MACHINE",
            commonStyle: {
              minWidth: "100px",
              flex: "0 0 100px",
              padding: "2px 8px",
              boxSizing: "border-box",
              lineHeight: "16px",
            },
            rowStyle: {
              display: "flex",
              alignItems: "center",
              scrollSnapAlign: "start",
            },
            cell(event) {
              return <StaffAtMachineCell {...event} />;
            },
            headerCell() {
              return (
                <div className="flex gap-2 items-center justify-between">
                  <Typography type="caption" color="onSurfaceHigh">
                    {t("staffAtMachine")}
                  </Typography>
                  <Chevron
                    sortDir={
                      sort.sortCol === "staff-at-machine"
                        ? sort.sortDir
                        : undefined
                    }
                    onClick={() => {
                      toggleSort({
                        col: "staff-at-machine",
                        firstDirection: "desc",
                      });
                    }}
                  />
                </div>
              );
            },
          },
          {
            id: "ACTION",
            commonStyle: {
              minWidth: "40px",
              flex: "0 0 40px",
              padding: "2px 8px",
              boxSizing: "border-box",
              lineHeight: "16px",
            },
            rowStyle: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              scrollSnapAlign: "start",
            },
            cell(event) {
              return <ActionDropDown machineId={event.machineId} />;
            },
            headerCell() {
              return null;
            },
          },
        ]}
      />
  );
}
